<template>
    <div class="wrapper">
        <el-container :class="{ 'center-container': screenWidth > 1920 }">
            <!-- <div class="portal-theme-background-img" :style="
          portal_theme_background_img_dragon
            ? 'background:url(' + portal_theme_background_img_dragon + ')'
            : ''
        "></div> -->
            <!-- <img class="background-wave" src="@/assets/images/home_bg.png" alt="" /> -->
            <el-header class="header" :style="[portal_header, portal_nav_bak_color]">
                <div class="header-left">
                    <div class="logo-div">
                        <img src="@/assets/images/head_logo.png" alt="" class="logo ">
                        <div class="company">
                            <div>河南云数聚网络科技有限公司</div>
                            <div class="en">Henan CDcenter Network Technology Co., Ltd.</div>
                        </div>
                    </div>

                    <!-- <ul class="toolbar">
                        <li v-for="(tb, index) in toolbarList" :class="{ active: tb.active }" :key="index"
                            @click="handleToolbarClick(tb)">
                            <div class="toolbar-text">
                                {{ tb.name }}
                            </div>
                        </li>
                    </ul> -->
                </div>

                <div class="header-right">
                    <div>
                        <el-dropdown @command="handleCommand" class="right-menu-item hover-effect" trigger="click">
                            <div class="avatar-wrapper">
                                <div class="user-avatar"><el-avatar :src="store.getters.avatar"
                                        @click.prevent="toCreativeSpace('创意空间')" /></div>
                                <span class="name-span">{{store.getters.userInfo.nickName}}</span>
                                <el-icon class="drop">
                                    <caret-bottom color="#fff" />
                                </el-icon>
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item command="center">个人中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    <!-- <div class="weather" v-show="false">
                        <iframe width="180" scrolling="no" height="50" frameborder="0" allowtransparency="true" :src="
                'https://i.tianqi.com?c=code&id=72&color=%23' +
                portal_tianqi +
                '&icon=1&site=18'
              "></iframe>
                    </div> -->
                    <div class="logout" @click.prevent="logout">
                        <img src="@/assets/images/exits.png" alt="">
                        <span style="vertical-align: middle;">退出登录</span>
                    </div>
                </div>
            </el-header>
            <el-container>
                <!-- <el-aside class="affix-container" v-show="appToolbarShow">
                    <div class="info">
                        <div class="avatar cursor-pointer">
                            <el-avatar :size="90" :src="store.getters.avatar"
                                @click.prevent="toCreativeSpace('创意空间')" />
                        </div>
                        <span class="username">你好，{{ store.getters.userInfo.nickName }}</span>
                    </div>
                    <div v-show="appToolbarShow">
                        <div style="height: 100%">
                            <div class="shortcut" v-if="clientList.length > 0">
                                <div class="item" v-for="(item, i) in clientList.slice(0, 8)" :key="i"
                                    @click="clientClickHandler(item)">
                                    <el-image :src="item.clientIconUrl" class="image-wh">
                                        <template #error>
                                            <div class="image-slot">
                                                <el-image :src="getAssetsUrl('portal/app.svg')"
                                                    class="image-wh-error"></el-image>
                                            </div>
                                        </template>
                                    </el-image>
                                    <div class="main-text client-name">{{ item.clientName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-aside> -->
                <el-main>
                    <el-collapse-transition>
                        <router-view></router-view>
                    </el-collapse-transition>
                </el-main>
            </el-container>
            <!--   <el-dialog
        v-model="dialogVisible"
        title="修改密码"
        display="block"
        width="500px"
        :show-close="false"
        :center="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="true"
      >
        <div class="pass-title">
          为了您和公司的信息安全，消除不必要的隐患，请立即修改原始密码！
        </div>
        <el-form :model="passForm" ref="pd" :rules="passwordRules">
          <el-form-item label="&nbsp;&nbsp;&nbsp;新密码" prop="newPassword">
            <el-input type="password" v-model="passForm.newPassword" />
          </el-form-item>
          <el-form-item label="确认密码" prop="newPasswordCopy">
            <el-input type="password" v-model="passForm.newPasswordCopy" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="firstUpdatePassword()"
              >确认</el-button
            >
          </span>
        </template>
      </el-dialog>
    
     -->

            <Footer></Footer>
        </el-container>
        <el-dialog title="个人中心" v-model="dialogVisible" display="block" width="50%" :center="true"
            :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true">
            <PerSonal />
        </el-dialog>
    </div>
</template>
<script setup>
    import PerSonal from "@/views/portal/creativeSpace/personalCenter.vue";
    import { ElMessageBox, ElMessage } from "element-plus";
    import store from "@/store";
    import Footer from "@/layout/components/footer/index.vue";
    import { computed, onMounted, reactive, ref, watch } from "vue";
    import { useRouter } from "vue-router";
    import {
        updateEmail,
        updatePassword,
        updateEmailByPortal,
        updatePasswordByPortal,
    } from "@/api/system/user.js";
    import { getEpidList } from "@/api/portal/epid.js";
    import { ArrowRight } from "@element-plus/icons-vue";
    import { queryByInit } from "@/api/portal/theme";

    const userInfo = store.getters.userInfo;
    const passForm = ref({
        newPassWord: "",
        newPasswordCopy: "",
    });
    const { proxy } = getCurrentInstance();
    const portal_theme_background_img_dragon = computed(() => store.state.portalSettings.backgroundImg);
    const portal_title = computed(() => store.state.portalSettings.portalTitle);
    const portal_header = computed(() => store.state.portalSettings.portalHeader);
    const portal_title_bak_color = computed(() => store.state.portalSettings.portalTitleBakColor);
    const portal_nav_bak_color = computed(() => {
        return store.state.portalSettings.portalNavBakColor;
    });
    const portal_logo = computed(() => {
        return store.state.portalSettings.portalLogo;
    });
    const portal_user_detail = computed(() => store.state.portalSettings.portalUserDetail);
    const main_color = computed(() => store.state.portalSettings.mainColor);
    const portalColor = computed(() =>
        store.state.portalSettings.portalColor ? store.state.portalSettings.portalColor.color : ""
    );

    const dialogVisible = ref(false);
    //天气颜色
    const portal_tianqi = computed(() =>
        store.state.portalSettings.portalHeader == "header_black" ? "262626" : "ffffff"
    );
    const appToolbarShow = ref(true);
    const clientList = ref([]);
    const clientPrefix = "clientId:";
    const toolbarList = reactive([
        { name: "首页", path: "/portal/workbench", active: true },
        { name: "新闻中心", path: "/portal/portalHome", active: false },
        // { name: "知识中心", type: "zszx", path: "/portal/knowledge", active: false },
        // { name: "能力中心", type: "nl" },
        { name: "应用中心", path: "/portal/applicationCenter", active: false },
        { name: "个人中心", path: "/portal/creativeSpace", active: false },
        // { name: "意见征集", type: "yj" },
    ]);
    const router = useRouter();
    const input = ref("");
    const handleToolbarClick = (tb) => {
        if (tb.type === "yj") {
            window.open("http://bbs.eathink.com.cn/superGeek/1?plate=4");
        } else if (tb.type === "zszx") {
            window.open("https://yxjt.eathink.com.cn:9443/sso");
        } else if (tb.type === "nl") {
            window.open("http://products.eathink.com.cn:7080/");
        } else {
            // 判断常用系统是否显示
            showAppToolbar(tb.path);
            toolbarList.map((tbItem) => {
                tbItem.active = false;
                if (tbItem.path === tb.path && tbItem.name === tb.name) {
                    tbItem.active = true;
                }
            });
            router.push(tb.path);
        }
    };
    //    判断密码是否是原始密码
    //  function judge(){
    //        if(userInfo.sysUserEx.passFlag=='0'){
    //            dialogVisible.value=true
    //        }
    //  }
    //  judge();

    function firstUpdatePassword() {
        proxy.$refs.pd.validate((valid) => {
            if (valid) {
                updatePassword({
                    userId: userInfo.userId,
                    userName: userInfo.userName,
                    password: "yxdk8888",
                    newPassword: passForm.value.newPassword,
                }).then((res) => {
                    if (res) {
                        proxy.$message({
                            message: "修改成功!",
                            type: "success",
                        });
                        dialogVisible.value = false;
                        logoutFirst();
                    } else {
                        proxy.$message({
                            message: "修改失败，请重新输入!",
                            type: "error",
                        });
                    }
                });
            }
        });
    }
    const passwordRules = ref({
        newPassword: [
            { required: true, trigger: "blur", message: "密码不能为空" },
            {
                required: true,
                validator: (rule, value, callback) => {
                    if ("yxdk8888" == value) {
                        callback(new Error("输入的密码不能是yxdk8888"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
            {
                required: true,
                validator: (rule, value, callback) => {
                    const str = /^((?=\S*?[A-z])(?=\S*?[0-9]).{6,})\S$/;
                    if (str.test(value)) {
                        callback();
                    } else {
                        callback("密码要大于6位,且不能有空格,包含字母和数字");
                    }
                },
                trigger: "blur",
            },
        ],
        newPasswordCopy: [
            { required: true, trigger: "blur", message: "密码不能为空" },
            {
                required: true,
                validator: (rule, value, callback) => {
                    if (passForm.value.newPassword !== value) {
                        callback(new Error("输入的密码不一致"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
    });
    const screenWidth = ref(document.body.clientWidth);
    watch(screenWidth, (n, o) => {
        console.log("当前页面宽度为:" + n + "px");
    });

    onMounted(() => {
        window.onresize = () =>
            (() => {
                screenWidth.value = document.body.clientWidth;
            })();

        const currentPath = router.currentRoute.value.path;

        showAppToolbar(currentPath);
        toolbarList.map((tbItem) => {
            tbItem.active = false;
            if (tbItem.path === currentPath) {
                tbItem.active = true;
            }
        });

        // 获取应用中心
        clientList.value = store.getters.clientList;
    });
    function viewNews(url) {
        window.open(url);
    }

    function riskInquiry() {
        window.open("http://bmfw.www.gov.cn/yqfxdjcx/index.html");
    }
    function travelPolicy() {
        window.open(
            "https://a2j1a3.smartapps.cn/doctor/pages/epidemic/index/index?footer=%20&title=%E5%90%84%E5%9C%B0%E9%98%B2%E6%8E%A7%E6%94%BF%E7%AD%96&id=14"
        );
    }

    function logoutFirst() {
        store.dispatch("SSOLogOut").then(() => {
            location.href = "/login";
        });
    }
    function logout() {
        ElMessageBox.confirm("确定注销并退出系统吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "btnConfirm",
            type: "warning",
        })
            .then(() => {
                store.dispatch("SSOLogOut").then(() => {
                    location.href = "/login";
                });
            })
            .catch(() => {});
    }
    // 判断常用系统是否显示
    function showAppToolbar(path) {
        if (path === "/portal/applicationCenter") {
            appToolbarShow.value = false;
        } else {
            appToolbarShow.value = true;
        }
    }

    /**
     * 点击client触发的事件
     */
    const clientClickHandler = (item) => {
        // 记录ID被点击的次数
        incrementClientCount(item.clientId);
        window.open(item.webServerRedirectUri);
    };
    const getClientCount = (clientId) => {
        return localStorage.getItem(clientPrefix + clientId);
    };
    const incrementClientCount = (clientId) => {
        const clientCount = getClientCount(clientId);
        if (clientCount) {
            localStorage.setItem(clientPrefix + clientId, Number(clientCount) + 1);
        } else {
            localStorage.setItem(clientPrefix + clientId, 1);
        }
    };
    //跳转个人空间
    function toCreativeSpace(tabName) {
        handleToolbarClick(toolbarList.filter((item) => item.name == tabName)[0]);
    } //加载皮肤
    // queryByInit().then((res) => {
    //     let storeKeyFun = "portalSettings/changeSetting";
    //     // localStorage.setItem("portal-setting", JSON.stringify(res.themeConfig))
    //     localStorage.setItem("portal-setting", JSON.stringify(res.themeConfig));
    //     const portalSetting = JSON.parse(res.themeConfig);
    //     store.state.portalSettings.backgroundImg = portalSetting.backgroundImg;
    //     store.state.portalSettings.portalColor = portalSetting.portalColor;
    //     store.state.portalSettings.portalHeader = portalSetting.portalHeader;
    //     store.state.portalSettings.portalTitleBakColor = portalSetting.portalTitleBakColor;
    //     store.state.portalSettings.portalNavBakColor = portalSetting.portalNavBakColor;
    //     store.state.portalSettings.contentBakColor = portalSetting.contentBakColor;
    //     store.state.portalSettings.portalLogo = portalSetting.portalLogo;
    //     store.state.portalSettings.portalUserDetail = portalSetting.portalUserDetail;
    //     store.state.portalSettings.mainColor = { color: "#00BBFE" };
    // });

    function handleCommand(command) {
        switch (command) {
            case "center":
                router.push("/portal/creativeSpace");
                break;
            case "logout":
                logout();
                break;
            default:
                break;
        }
    }
</script>


<style>
    .popper-class {
        background: rgba(0, 0, 0, 0) !important;
        border: 0px !important;
        padding: 0px !important;
    }
</style>

<style lang='scss' scoped>
    .affix-container::-webkit-scrollbar {
        width: 0 !important;
    }
    .shortcut::-webkit-scrollbar {
        width: 0 !important;
    }
    .affix-container {
        margin-bottom: 0px !important;
        overflow: hidden;
        background: url("@/assets/images/aside_bg.png") no-repeat;
        background-position: center;
        background-size: 100% 100%;
    }
    .logo {
        height: 40px;
        width: auto;
        margin-right: 10px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .wrapper {
        background: #f9f9f9;
    }
    .el-container {
        position: relative;
        overflow: hidden;
        max-width: 1920px;
        height: 100vh;
    }
    .yqclass {
        cursor: pointer;
        padding: 2px 8px;
        font-size: 13px;
        font-weight: 400;
        background-color: #00b87a;
        margin-right: 16px;
        border-radius: 4px;
    }
    .slogan {
        width: 210px;
        padding: auto;
        position: fixed;
        bottom: 10px;
        left: 15px;
        //  _position:absolute;

        .imageclass {
            width: 100%;
        }
    }
    .category {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
        margin: 20px 39px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .all-application {
            cursor: pointer;

            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            color: #8c8c8c;
            text-align: right;
            &:hover {
                color: v-bind(portalColor);
            }
        }
    }
    .popper-class {
        padding: 0px;
    }
    .afix {
        width: 210px;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url("../assets/images/epid-back.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .epid-label {
        position: fixed;
        bottom: 60px;

        margin-left: -24px;
        padding: 10px 6px;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 24px;

        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */

        text-align: justify;

        /* 危险、成功、警告/orange-6 */

        color: #ffffff;

        background: #00b87a;
        border-radius: 0px 8px 8px 0px;
        // background-image: url("../assets/images/epid-title.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        .empt-image {
            width: 10px;
            height: 10px;
            margin-top: 2px;
        }
    }
    .epid-service {
        padding: 12px 0px;
        border-radius: 8px;
        width: 100%;
        .epid-title {
            cursor: pointer;
            padding: 0px 12px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            /* or 157% */
            border-left: 2px solid #f9a825;
            border-left-style: {
                border-radius: 1px;
            }
            text-align: justify;
            display: flex;
            align-items: center;
            color: #fa8c16;
            &:hover {
                font-weight: 600;
            }
        }
        .epid-seach {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 12px;
            .base {
                width: 80px;
                height: 32px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                /* 危险、成功、警告/bule-6 */
                border-radius: 16px;

                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                /* identical to box height, or 157% */
                text-align: justify;
                color: #ffffff;
            }
            .base-font-risk {
                background-color: #1890ff;
                &:hover {
                    background: #40a9ff;
                }
            }
            .base-font-trave {
                background-color: #00b87a;
                &:hover {
                    background: #1fc488;
                }
            }
        }
    }
    .pass-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 157% */

        display: flex;
        align-items: center;

        /* 中性色/0.85 标题 */

        color: #262626;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        margin-bottom: 30px;
    }
    .center-container {
        left: 50%;
        margin-left: -960px;
    }
    .portal-theme-background-img {
        position: absolute;
        overflow: hidden;
        width: 1920px;
        height: 390px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .background-wave {
        position: absolute;
        top: 0px;
        left: -200px;
        width: 150%;
        height: 500px;
        /*z-index: -1;*/
    }
    /**    header  begin */
    .header {
        height: 84px;
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        background: #00bbfe !important;
        /* 中性色/1 white */
        z-index: 1;
        // color: #FFFFFF;
        // background: none !important;
        padding: 0 32px;
    }
    .header .header-left {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    .header .toolbar {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin-left: 100px;
        padding-left: 0;
        font-weight: 600;
        margin-left: 60px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .header .toolbar li {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #fff;
        margin-right: 70px;
        height: 114px;
        line-height: 114px;
        min-width: 124px;
    }

    .header .toolbar .active {
        // background-color: #1ab394;
        // line-height: 50px;
        display: block;
        // vertical-align: middle;
        background: #fff;
        border-radius: 0px 0px 24px 24px;
        background-size: 100% 100%;
        .toolbar-text {
            font-size: 22px;
            color: #00bbfe;
            position: relative;
            height: 114px;
            line-height: 114px;
            letter-spacing: 0;

            &::before {
                content: "";
                height: 3px;
                position: absolute;
                width: 40%;
                left: 50%;
                transform: translateX(-50%);
                bottom: 33px;
                background: #00bbfe;
            }
            // border-bottom: 2px solid #3585b8;
        }
    }
    .toolbar-text {
        text-align: center;
        width: 100%;
        // padding: 0 17px;
    }
    .search {
        --el-border-radius-base: 16px;
    }

    .header .header-right {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }
    .header .header-right .weather {
        // display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        padding-top: 25px;
        pointer-events: none;
    }
    .header .header-right .weather span {
        margin: 0 20px;
    }
    /**     header  end **/

    .el-aside {
        width: 400px;
        padding: 40px 0;
        margin: 20px 0 30px 32px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
    }

    .info {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .info .avatar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .el-icon {
        vertical-align: middle;
        font-size: 10px;
    }
    .info .welcome {
        font-weight: 600;
        color: rgb(29, 28, 28);
    }
    .shortcut {
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-items: center;
        text-align: center;
        padding: 20px 20px 0;
    }

    .shortcut .item {
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: column;
        // margin: 7px 0 20px;
        padding: 16px 0;
        margin: 0 3% 20px;
        width: 26%;

        cursor: pointer;
        text-align: center;
        &:hover {
            background: rgba(173, 26, 31, 0.08);
            border-radius: 8px;
        }
    }
    .item:hover > .main-text {
        color: #00bbfe;
    }
    .shortcut .item img {
        width: 56px;
        height: 56px;
    }
    .shortcut .item span {
        text-align: center;
        color: #595959;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
    }
    .footer {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
    }
    .footer span {
        margin-right: 10px;
    }
    .logout {
        font-size: 18px;
        letter-spacing: 0.49px;
        cursor: pointer;
        color: #fff;
    }
    .logout img {
        width: 18px;
        vertical-align: middle;
        margin-right: 8px;
    }
    .username {
        padding: 12px 0;
        font-size: 20px;
        color: #00bbfe;
        letter-spacing: 0.62px;
        font-weight: 500;
    }
    .job-num {
        padding: 8px 20px;
        background: #ffffff;
        border-radius: 16px;
        font-size: 16px;
        line-height: 16px;
        color: #6d7278;
        letter-spacing: 0.49px;
        font-weight: 500;
        .line {
            line-height: 16px;
            padding: 0 10px;
            color: rgba(109, 114, 120, 0.2);
        }
    }
    .logo-div {
        display: flex;
        align-items: center;
    }
    .name {
        margin-bottom: 7px;
        font-size: 26px;
        letter-spacing: 0.8px;
        font-weight: 700;
        color: #ffffff;
    }
    .en-name {
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0.17px;
    }
    .client-name {
        font-size: 16px;
        color: #333333;
        letter-spacing: 0.43px;
        line-height: 14px;
        margin-top: 10px;
    }
    .user-avatar img {
        width: 28px;
        height: 28px;
    }
    .name-span {
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        padding: 0 10px;
    }
    .avatar-wrapper {
        display: flex;
        align-items: center;
    }
    .right-menu-item {
        margin-right: 40px;
        cursor: pointer;
    }
    .drop {
        font-size: 24px;
    }
    .logo-center {
        height: 45px;
        img {
            height: 100%;
            width: auto;
        }
    }
    .company {
        padding-left: 18px;
        font-size: 26px;
        color: #ffffff;
        letter-spacing: 0.3px;
        .en {
            margin-top: 4px;
            font-size: 16px;
            letter-spacing: 0.15px;
        }
    }
</style>
<style>
    .el-badge__content {
        border: 0;
    }
    .el-main {
        overflow: hidden;
        padding: 20px 20px 0px 30px;
    }
    html::-webkit-scrollbar {
        width: 0 !important;
    }
    el-main::-webkit-scrollbar {
        width: 0 !important;
    }
    .image-wh {
        width: 56px;
        height: 56px;
        /* box-shadow: 0px 4px 8px 0px rgba(81, 184, 53, 0.15); */
        /* border-radius: 8px; */
        margin: 0px auto;
    }
    .image-wh-error {
        width: 56px;
        height: 56px;
        border-radius: 8px;
    }
    .btnConfirm {
        background: #00bbfe !important;
        border: none;
    }
    .el-dropdown-menu__item {
        font-size: 16px !important;
    }
    .el-dropdown-menu__item:not(.is-disabled):focus {
        background-color: #e5f2ff !important;
        color: #00bbfe !important;
    }
</style>
