<template>
    <div :class="classObj" class="app-wrapper" :style="{ '--current-color': theme }">
        <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
        <sidebar v-if="!sidebar.hide" class="sidebar-container" />
        <div :class="{ hasTagsView: needTagsView, sidebarHide: sidebar.hide }" class="main-container">
            <div :class="{ 'fixed-header': fixedHeader }">
                <navbar @setLayout="setLayout" />
                <tags-view v-if="needTagsView" />
            </div>
            <app-main />
            <settings ref="settingRef" />
        </div>
    </div>
</template>

<script setup>
    import { useWindowSize } from "@vueuse/core";
    import Sidebar from "./components/Sidebar/index.vue";
    import { AppMain, Navbar, Settings, TagsView } from "./components";
    import defaultSettings from "@/settings";
    import { queryByInit } from "@/api/portal/theme";

    const store = useStore();
    const theme = computed(() => store.state.settings.theme);
    const sideTheme = computed(() => store.state.settings.sideTheme);
    const sidebar = computed(() => store.state.app.sidebar);
    const device = computed(() => store.state.app.device);
    const needTagsView = computed(() => store.state.settings.tagsView);
    const fixedHeader = computed(() => store.state.settings.fixedHeader);

    const classObj = computed(() => ({
        hideSidebar: !sidebar.value.opened,
        openSidebar: sidebar.value.opened,
        withoutAnimation: sidebar.value.withoutAnimation,
        mobile: device.value === "mobile",
    }));

    const { width, height } = useWindowSize();
    const WIDTH = 992; // refer to Bootstrap's responsive design

    watchEffect(() => {
        if (device.value === "mobile" && sidebar.value.opened) {
            store.dispatch("app/closeSideBar", { withoutAnimation: false });
        }
        if (width.value - 1 < WIDTH) {
            store.dispatch("app/toggleDevice", "mobile");
            store.dispatch("app/closeSideBar", { withoutAnimation: true });
        } else {
            store.dispatch("app/toggleDevice", "desktop");
        }
    });

    function handleClickOutside() {
        store.dispatch("app/closeSideBar", { withoutAnimation: false });
    }

    const settingRef = ref(null);
    function setLayout() {
        settingRef.value.openSetting();
    }

    //加载皮肤
    // queryByInit().then(res => {
    //   let storeKeyFun = "portalSettings/changeSetting"
    //   localStorage.setItem('portal-setting', JSON.stringify(res.themeConfig));
    //   const portalSetting = JSON.parse(res.themeConfig)
    //   store.state.portalSettings.backgroundImg = portalSetting.backgroundImg;
    //   store.state.portalSettings.portalColor = portalSetting.portalColor;
    //   store.state.portalSettings.portalHeader = portalSetting.portalHeader;
    //   store.state.portalSettings.portalTitleBakColor = portalSetting.portalTitleBakColor;
    //   store.state.portalSettings.portalNavBakColor = portalSetting.portalNavBakColor;
    //   store.state.portalSettings.contentBakColor = portalSetting.contentBakColor;
    //   store.state.portalSettings.portalLogo = portalSetting.portalLogo;
    //   store.state.portalSettings.portalUserDetail = portalSetting.portalUserDetail;
    // })
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import "@/assets/styles/variables.module.scss";

    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;

        &.mobile.openSidebar {
            position: fixed;
            top: 0;
        }
    }

    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .fixed-header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: calc(100% - #{$base-sidebar-width});
        transition: width 0.28s;
    }

    .hideSidebar .fixed-header {
        width: calc(100% - 54px);
    }

    .mobile .fixed-header {
        width: 100%;
    }
</style>