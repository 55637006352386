<template>

    <div style="    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
   
    ">
        <!-- <div>
            <img class="imageclass" :src="proxy.getAssetsUrl('portal/layout/slogantwo.png')" />
        </div> -->
        <div>
            <el-footer class="auxiliary-text footer">
                <span>技术支持：河南云数聚网络科技有限公司&nbsp;&nbsp;&nbsp;</span>
                <!-- <el-popover :width="150"
                    popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px;"
                    placement="top">
                    <template #reference>
                        <span style="display:flex;align-items:center"> <img src="@/assets/portal/wechat.svg"
                                alt="">&nbsp;微信公众号</span>
                    </template>
                    <template #default>
                        <div class="demo-rich-conent"
                            style="display: flex; gap: 16px; flex-direction: column;align-items: center">
                            <img src="@/assets/portal/home/wexin.png" style="width:150px ;height:150px" />
                        </div>
                    </template>
                </el-popover> -->

                <!-- <el-popover placement="top-start" :width="240" trigger="hover"
                    content="官方邮箱：yuxindianke@eathink.com.cn">
                    <template #reference>
                        <span style="display:flex;align-items:center"> <img src="@/assets/portal/email.svg"
                                alt="">&nbsp;官方邮箱</span>
                    </template>
                </el-popover> -->
            </el-footer>
        </div>
    </div>
</template>

<script setup>
    // const { queryParams, form, rules } = toRefs(data);
    import store from "@/store";
    import { addFeedback, updateFeedback } from "@/api/portalManage/feedback";

    const { proxy } = getCurrentInstance();
    //意见反馈
    const open = ref(false);
    function changeOpen() {
        open.value = !open.value;
    }
    const buttonLoading = ref(false);
    const data = reactive({
        form: {
            feedbackType: 0,
            anonymous: 0,
            userName: store.getters.name,
            phonenumber: store.getters.userInfo.phonenumber,
            createBy: store.getters.name,
            delFlag: 0,
            processingStatus: 0,
        },
        queryParams: {
            pageNum: 1,
            pageSize: 10,
            id: undefined,
            feedbackType: 0,
            systemName: undefined,
            anonymous: 0,
            userName: store.getters.name,
            phonenumber: store.getters.userInfo.phonenumber,
            content: undefined,
            createBy: store.getters.name,
            createTime: undefined,
            updateBy: undefined,
            updateTime: undefined,
            delFlag: 0,
            processingStatus: 0,
        },
        rules: {
            feedbackType: [{ required: true, message: "反馈类型不能为空", trigger: "change" }],
            systemName: [{ required: true, message: "系统名称不能为空", trigger: "blur" }],
            anonymous: [{ required: true, message: "是否匿名：0匿名，1实名不能为空", trigger: "blur" }],
            userName: [{ required: true, message: "用户姓名不能为空", trigger: "blur" }],
            phonenumber: [{ required: true, message: "手机号码不能为空", trigger: "blur" }],
            content: [{ required: true, message: "反馈内容不能为空", trigger: "blur" }],
            delFlag: [{ required: true, message: "0正常，2删除不能为空", trigger: "blur" }],
            processingStatus: [
                { required: true, message: "0:未处理，1处理中，2已处理不能为空", trigger: "blur" },
            ],
        },
    });
    const { queryParams, form, rules } = toRefs(data);

    /** 提交按钮 */
    function submitForm() {
        proxy.$refs["feedbackRef"].validate((valid) => {
            if (valid) {
                buttonLoading.value = true;
                if (form.value.id != null) {
                    updateFeedback(form.value)
                        .then((response) => {
                            proxy.$modal.msgSuccess("修改成功");
                            open.value = false;
                        })
                        .finally(() => {
                            buttonLoading.value = false;
                        });
                } else {
                    addFeedback(form.value)
                        .then((response) => {
                            proxy.$modal.msgSuccess("反馈成功！");
                            open.value = false;
                            reset();
                        })
                        .finally(() => {
                            buttonLoading.value = false;
                        });
                }
            }
        });
    }
    // 取消按钮
    function cancel() {
        open.value = false;
        reset();
    }
    // 表单重置
    function reset() {
        form.value = {
            feedbackType: 0,
            anonymous: 0,
            userName: store.getters.name,
            phonenumber: store.getters.userInfo.phonenumber,
            createBy: store.getters.name,
            delFlag: 0,
            processingStatus: 0,
            systemName: null,
            content: null,
            createTime: null,
            updateBy: null,
            updateTime: null,
        };
        proxy.resetForm("feedbackRef");
    }
</script>
<style lang="scss" scoped>
    .el-tooltip__trigger {
        margin-right: 10px;
    }

    .footer {
        display: -webkit-flex;
        /* Safari */
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        color: #b3b5b8;
        height: 40px;
    }

    .imageclass {
        height: 40px;
    }
</style>
