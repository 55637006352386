<template>
    <div class="personal-container">
        <el-row class="container">
            <el-col :span="12" class="left-col">
                <div class="userInfo">
                    基本资料
                </div>
                <div class="left">
                    <div class="text-center">
                        <!-- <userAvatar :user="state.user" /> -->

                        <img :src="store.getters.avatar" class="avatar-wh" />
                    </div>
                    <div class="list">
                        <!-- <div class="list-item">
                        <div class="list-item-title">工号</div>
                        <div class="list-item-content">{{ state.user.userName }}</div>
                    </div> -->
                        <div class="list-item">
                            <div class="list-item-title">用户名称</div>
                            <div class="list-item-content">{{ state.user.nickName }}</div>
                        </div>
                        <div class="list-item">
                            <div class="list-item-title">手机号码</div>
                            <div class="list-item-content">{{ state.user.phonenumber }}</div>
                        </div>
                        <div class="list-item">
                            <div class="list-item-title">个人邮箱</div>
                            <div class="list-item-content" style="margin-right:5px">
                                <el-input v-model="email" v-if="isEdit"></el-input>
                                <span v-else>{{ state.user.email }}</span>
                            </div>
                            <el-button type="warning" @click="edit" v-if="!isEdit"
                                style="background: #00BBFE;border: none">修改</el-button>
                            <el-button type="warning" @click="confirm" v-else>确认</el-button>
                            <el-button v-if="isEdit" @click="isEdit=false">取消</el-button>
                        </div>
                        <div class="list-item" style=" align-items: start;">
                            <div class="list-item-title">所属部门</div>
                            <div class="list-item-content" v-if="state.user.dept">
                                <div class="content-list" v-for="(item, index) in state.deptList" :key="index">
                                    <div class="item">
                                        <span>
                                            {{ item.dept.deptName }} / {{ item.dept.affilUniqOrgNm }}
                                        </span>
                                        <!-- <span v-if="item.sysUserEx">
                                        <span class="item-tager main-job"
                                            v-if="item.deptId == item.sysUserEx.mainDeptId">
                                            主职
                                        </span>
                                        <span class="item-tager part-time" v-else>
                                            兼职
                                        </span>
                                    </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" class="right-col">
                <div class="userInfo">
                    修改头像
                </div>
                <div class="update-avatar image-list">
                    <!-- <el-form label-width="120px">
                        <el-form-item label="邮箱">
                            <el-input style="width:300px" v-model="state.user.email" disabled clearable />
                        </el-form-item>
                    </el-form> -->
                    <!-- <div v-for="item, index in 12" :key="index"> -->
                    <!-- <img v-if="index != 11" :src="splicingSrc(index)" class="img-wh"
                            @click="changeAvatar(splicingSrc(index))" /> -->
                    <updateUserAvatar></updateUserAvatar>
                    <!-- </div> -->
                </div>
                <div class="userInfo">
                    修改密码
                </div>

                <div class="update-pass">
                    <el-form ref="formLabelRef" :label-position="labelPosition" :model="formLabelAlign"
                        label-width="70px" :rules="passwordRules">
                        <el-form-item label="旧密码" prop="oldPassword">
                            <el-input type="password" v-model="formLabelAlign.oldPassword" />
                        </el-form-item>
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input type="password" v-model="formLabelAlign.newPassword" />
                        </el-form-item>
                        <el-form-item label="确认密码" prop="newPasswordCopy">
                            <el-input type="password" v-model="formLabelAlign.newPasswordCopy" />
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="submitFormPass()"
                                style="background:#00BBFE;border: none;">保存</el-button>
                            <el-button style="background: #DEDAD5;border: none;color:#fff"
                                @click="resetFormPass()">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>

            </el-col>
        </el-row>
    </div>
</template>
<script setup>
    import userAvatar from "../../system/user/profile/userAvatar";
    import userInfo from "../../system/user/profile/userInfo";
    import resetPwd from "../../system/user/profile/resetPwd";
    import { getUserProfile } from "@/api/system/user";
    import { updateAvatar } from "@/api/portal/creativetion/person";
    import updateUserAvatar from "../creativeSpace/updateUserAvatar.vue";
    import { deleteFileUrl } from "@/api/system/oss";
    import { ref } from "vue";
    import store from "@/store";
    import {
        updateEmail,
        updatePassword,
        updateEmailByPortal,
        updatePasswordByPortal,
    } from "@/api/system/user.js";
    const { proxy } = getCurrentInstance();
    const activeTab = ref("userinfo");
    const isEdit = ref(false);
    const state = reactive({
        user: {},
        roleGroup: {},
        postGroup: {},
        deptList: [],
    });
    const dynamicValidateForm = ref({
        email: "",
    });
    const email = ref("");
    const avatarList = [
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-1.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-2.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-3.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-4.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-5.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-6.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-7.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-8.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-9.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-10.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-11.png",
        "http://oss.eathink.com.cn:9000/yxdk-public/8402fe2a55de4718aaeefb8e4849f3bcb.jpg",
    ];
    function changeAvatar(data) {
        updateAvatar(data).then((response) => {
            // console.log(response,"修改头像的数据")
            if (response) {
                // console.log(avatarList.indexOf(store.getters.avatar)<0,"avatarList.indexOf(store.getters.avatar)<0")
                if (store.getters.avatar != null && avatarList.indexOf(store.getters.avatar) < 0) {
                    // console.log(store.getters.avatar,"store.getters.avatar")
                    deleteFileUrl(store.getters.avatar).then((res) => {
                        //   console.log(res)
                    });
                }
                store.commit("SET_AVATAR", data);
            }
        });
    }
    function splicingSrc(data) {
        return "http://oss.eathink.com.cn:9000/yxdk-public/2022/05/30/user-" + (data + 1) + ".png";
    }
    function getUser() {
        getUserProfile().then((response) => {
            // console.log(response, "1234")
            state.user = response.user;
            state.roleGroup = response.roleGroup;
            state.postGroup = response.postGroup;
            state.deptList = response.deptList;
        });
    }
    getUser();
    const labelPosition = ref("right");
    const formLabelAlign = ref({
        oldPassword: "",
        newPassword: "",
        newPasswordCopy: "",
    });

    const passwordRules = ref({
        oldPassword: [
            { required: true, trigger: "blur", message: "密码不能为空" },
            // {
            //     required: true,
            //     validator: (rule, value, callback) => {
            //         const str = /^((?=\S*?[A-z])(?=\S*?[0-9]).{6,})\S$/
            //         if (str.test(value)) {
            //             callback()
            //         }
            //         else {
            //             callback("密码要大于6位,且不能有空格,包含字母和数字")
            //         }
            //     },
            //     trigger: "blur"
            // }
        ],
        newPassword: [
            { required: true, trigger: "blur", message: "密码不能为空" },
            {
                required: true,
                validator: (rule, value, callback) => {
                    // const str = /^((?=\S*?[A-z])(?=\S*?[0-9]).{6,})\S$/;
                    const str = /^\S{6,}$/;
                    if (str.test(value)) {
                        callback();
                    } else {
                        // callback("密码要大于6位,且不能有空格,包含字母和数字");
                        callback("密码要大于6位,且不能有空格");
                    }
                },
                trigger: "blur",
            },
        ],
        newPasswordCopy: [
            { required: true, trigger: "blur", message: "密码不能为空" },

            {
                required: true,
                validator: (rule, value, callback) => {
                    if (formLabelAlign.value.newPassword !== value) {
                        callback(new Error("输入的密码不一致"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
    });
    function submitFormPass() {
        proxy.$refs.formLabelRef.validate((valid) => {
            if (valid) {
                updatePassword({
                    userId: state.user.userId,
                    userName: state.user.userName,
                    password: formLabelAlign.value.oldPassword,
                    newPassword: formLabelAlign.value.newPassword,
                }).then((res) => {
                    if (res) {
                        proxy.$message({
                            message: "修改成功!",
                            type: "success",
                        });
                    } else {
                        updatePassword({
                            userId: state.user.userId,
                            userName: state.user.userName,
                            password: formLabelAlign.value.newPassword,
                            newPassword: formLabelAlign.value.oldPassword,
                        }).then((res) => {
                            proxy.$message({
                                message: "修改失败!",
                                type: "error",
                            });
                        });
                    }
                    resetFormPass();
                });
            }
        });
    }
    function resetFormPass() {
        proxy.$refs.formLabelRef.resetFields();
    }
    // 修改邮箱
    function edit() {
        isEdit.value = true;
        email.value = state.user.email;
    }
    function confirm() {
        const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (email.value !== "" && !reg.test(email.value)) {
            proxy.$message({
                message: "请输入正确的邮箱!",
                type: "error",
            });
            return;
        }
        isEdit.value = false;
        updateEmail({
            userId: state.user.userId,
            userName: state.user.userName,
            email: email.value,
        }).then((res) => {
            if (res) {
                proxy.$message({
                    message: "修改成功!",
                    type: "success",
                });
                isEdit.value = false;
                getUser();
            } else {
                proxy.$message({
                    message: "修改失败!",
                    type: "error",
                });
            }
        });
    }
</script>
<style lang="scss" scoped>
    .personal-container {
        background-color: #ffffff;
        padding: 25px;
        min-height: calc(72vh - 5px);
        border-radius: 8px;

        .container {
            height: 100%;

            .userInfo {
                // border-left: 2px solid #3585b8;
                padding-left: 10px;
                align-items: center;
                position: relative;
                font-size: 16px;
                color: #333333;
                font-weight: 600;
                &::before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 4px;
                    height: 19px;
                    border-radius: 3px;
                    background: #00bbfe;
                }
            }
            .left {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .left-col {
                border-right: 1px solid rgba(0, 0, 0, 0.08);
                padding-right: 20px;

                .avatar-wh {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                }

                .list {
                    display: flex;
                    flex-direction: column;

                    .list-item {
                        margin-top: 24px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        .list-item-title {
                            min-width: 60px;
                            font-family: "Noto Sans CJK SC";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 22px;
                            text-align: right;
                            color: #999;
                        }

                        .list-item-content {
                            margin-left: 24px;
                            display: flex;
                            justify-content: left;
                            flex-direction: column;
                            font-family: "Noto Sans CJK SC";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;
                            color: #262626;

                            .content-list {
                                display: flex;
                                flex-direction: column;

                                .item {
                                    margin-bottom: 16px;
                                    // display: flex;
                                    flex-direction: row;

                                    .item-tager {
                                        display: inline-block;
                                        margin: 2px 5px;
                                        padding: 4px 8px;
                                        font-family: "Noto Sans CJK SC";
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 16px;
                                        background: #f5f5f5;
                                        border-radius: 10px;
                                        margin-left: 8px;
                                    }

                                    .main-job {
                                        color: #008a2f;
                                    }

                                    .part-time {
                                        color: #52c41a;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right-col {
                padding-left: 50px;

                .update-avatar {
                    margin-left: 5px;
                    margin-top: 10px;
                    margin-bottom: 40px;
                }

                .image-list {
                    width: 456px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .img-wh {
                        cursor: pointer;
                        margin-right: 20px;
                        margin-top: 16px;
                        width: 56px;
                        height: 56px;
                        border-radius: 50%;
                    }
                }

                .update-pass {
                    width: 60%;
                    margin-top: 25px;
                    .el-button {
                        padding: 6px 24px;
                        margin-right: 20px;
                        font-size: 18px;
                    }
                    ::v-deep .el-form-item__label {
                        font-size: 16px;
                    }
                }
            }
        }
    }
</style>

