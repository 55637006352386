import defaultSettings from '@/settings'
import { useDynamicTitle } from '@/utils/dynamicTitle'

const { sideTheme, showSettings, topNav, tagsView, fixedHeader, sidebarLogo, dynamicTitle } = defaultSettings

const storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || ''
const state = {
  activeName:"first"
}
const mutations = {
  CHANGE_TABS: (state, value) => {
    
      state.activeName = value
      console.log( state.activeName,"active")
  }
}

const actions = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

