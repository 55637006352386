import {
  doLoginByTicket,
  doSSOLogout,
  getInfo,
  getSSOInfo,
  login,
  logout,
} from '@/api/login'
import { getToken, removeToken, setToken } from '@/utils/auth'
import defAva from '@/assets/images/photo.png'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    userInfo: '',
    clientList: [],
    groupClient: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_CLIENT_LIST: (state, clientList) => {
      state.clientList = clientList
    },
    SET_GROUP_CLIENT: (state, groupClient) => {
      state.groupClient = groupClient
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid)
          .then((res) => {
            setToken(res.token)
            commit('SET_TOKEN', res.token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const user = res.user
            const avatar =
              user.avatar == ''
                ? defAva
                : import.meta.env.VITE_APP_BASE_API + user.avatar

            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.roles)
              commit('SET_PERMISSIONS', res.permissions)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_NAME', user.userName)
            commit('SET_USERINFO', user)
            commit('SET_AVATAR', avatar)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_CLIENT_LIST', [])
            commit('SET_GROUP_CLIENT', {})
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },

    // sso登录
    DoLoginByTicket({ commit }, ticket) {
      return new Promise((resolve, reject) => {
        doLoginByTicket(ticket)
          .then((res) => {
            setToken(res)
            commit('SET_TOKEN', res)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // sso获取用户信息
    getSSOInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getSSOInfo()
          .then((res) => {
            const user = res.user
            const avatar = user.avatar == '' ? defAva : user.avatar

            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.roles)
              commit('SET_PERMISSIONS', res.permissions)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_NAME', user.userName)
            commit('SET_USERINFO', user)
            commit('SET_AVATAR', avatar)
            commit('SET_CLIENT_LIST', res.clientList)
            commit('SET_GROUP_CLIENT', res.groupClient)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // SSO单点退出系统
    SSOLogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        doSSOLogout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default user
