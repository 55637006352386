<template>
    <div class="sidebar-logo-container" :class="{ 'collapse': collapse }"
        :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
        <transition name="sidebarLogoFade">
            <div class="sidebar-logo-link">

                <img style="width:50px;height:50px" src="@/assets/images/admin-logo.png" alt="">
                <span class="sidebar-title"
                    :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }}</span>
                <!-- <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }}</h1> -->
            </div>

            <!-- <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
      
        <h1  class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }}</h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
      
        <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }}</h1>
      </router-link> -->
        </transition>

    </div>
</template>

<script setup>
    import variables from "@/assets/styles/variables.module.scss";
    const { proxy } = getCurrentInstance();
    const portal_logo = computed(() =>
        store.state.portalSettings.portalLogo ? store.state.portalSettings.portalLogo : "logo_write"
    );
    defineProps({
        collapse: {
            type: Boolean,
            required: true,
        },
    });

    const title = ref("门户管理系统");
    const store = useStore();
    const sideTheme = computed(() => store.state.settings.sideTheme);
</script>

<style lang="scss" scoped>
    .sidebarLogoFade-enter-active {
        transition: opacity 1.5s;
    }

    .sidebarLogoFade-enter,
    .sidebarLogoFade-leave-to {
        opacity: 0;
    }

    .sidebar-logo-container {
        position: relative;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #2b2f3a;
        text-align: center;
        overflow: hidden;

        & .sidebar-logo-link {
            height: 100%;
            width: 100%;
            display: flex;
            /* 左右居中 */
            justify-content: center;
            /* 上下居中 */
            align-items: center;

            & .sidebar-logo {
                width: 32px;
                height: 32px;
                vertical-align: middle;
                margin-right: 12px;
            }

            & .sidebar-title {
                display: inline-block;
                margin: 0;
                color: #fff;
                font-weight: 600;
                line-height: 50px;
                font-size: 16px;
                font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
                vertical-align: middle;
            }
        }

        &.collapse {
            .sidebar-logo {
                margin-right: 0px;
            }
        }
    }
</style>