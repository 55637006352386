
import request from "../../utils/request";

/**
 * 文件上传
 * @param data
 * @returns {AxiosPromise}
 */
export function uploadFile(data) {
    return request({
        url: '/system/oss/upload',
        method: 'post',
        data: data
    })
}
export function deleteFile(data) {
    return request({
        url: '/system/oss/'+data,
        method: 'delete',
        
    })
}
export function deleteFileUrl(url) {
    return request({
        url: '/system/oss?url=' + url,
        method: 'delete'
    })
}
export function getFileList(data){
    return request({
        url:"/system/oss/listByIds",
        mothod:"get",
        params:data
    })
}
