import { queryByInit } from '@/api/portal/theme'
let ops = JSON.parse(localStorage.getItem('old-portal-setting')) || ''
let storageSetting = ''

// 中秋
const minAuFest = {
  backgroundImg: 'portal_theme_background_img_festival',
  portalColor: 'color_black',
  portalHeader: 'header_white',
  portalTitleBakColor: 'title_bak_festival',
  portalNavBakColor: '',
  contentBakColor: 'bak_color_festival',
  portalLogo: 'logo_write',
  portalUserDetail: 'user_detail_write',
}
// 国庆
const NationalDay = {
  backgroundImg: 'portal_theme_background_img_yuandan',
  portalColor: 'color_red',
  portalHeader: 'header_white',
  portalTitleBakColor: 'title_bak_festival',
  portalNavBakColor: '',
  contentBakColor: 'bak_color_red',
  portalLogo: 'logo_write',
  portalUserDetail: 'user_detail_write',
}
// 默认
const DEFAULT = {
  backgroundImg: 'portal_theme_background_img_dragon',
  portalColor: 'color_green',
  portalHeader: 'header_white',
  portalTitleBakColor: 'title_bak_green',
  portalNavBakColor: '',
  contentBakColor: 'bak_color_green',
  portalLogo: 'logo_write',
  portalUserDetail: 'user_detail_write',
  mainColor: 'main_color',
}

// 1.直接把原来的设空
// 2.把默认皮肤存入（原来不为空）
// 3.再次渲染时，不涉及上面的判断条件

// if(ops.storageSetting==undefined||ops.storageSetting==""){
//     localStorage.setItem('old-portal-setting', JSON.stringify({
//         storageSetting:storageSetting,
//         version: new Date()
//     }));
//     storageSetting =minAuFest
//     localStorage.setItem('portal-setting', JSON.stringify(storageSetting));
// }
// if("portal_theme_background_img_yuandan"!= ops.storageSetting.backgroundImg){
//     storageSetting =NationalDay
//     localStorage.setItem('old-portal-setting', JSON.stringify({
//         storageSetting:storageSetting,
//         version: new Date()
//     }));
//     localStorage.setItem('portal-setting', JSON.stringify(storageSetting));
// }

const style = {}

const state = {
  backgroundImg:
    storageSetting.backgroundImg === undefined
      ? style.backgroundImg
      : storageSetting.backgroundImg,
  portalColor:
    storageSetting.portalColor === undefined
      ? style.portalColor
      : storageSetting.portalColor,
  portalHeader:
    storageSetting.portalHeader === undefined
      ? style.portalHeader
      : storageSetting.portalHeader,
  portalTitleBakColor:
    storageSetting.portalTitleBakColor === undefined
      ? style.portalTitleBakColor
      : storageSetting.portalTitleBakColor,
  portalNavBakColor:
    storageSetting.portalNavBakColor === undefined
      ? ''
      : storageSetting.portalNavBakColor,
  contentBakColor:
    storageSetting.contentBakColor === undefined
      ? style.contentBakColor
      : storageSetting.contentBakColor,
  portalLogo:
    storageSetting.portalLogo === undefined
      ? style.portalLogo
      : storageSetting.portalLogo,
  portalUserDetail:
    storageSetting.portalUserDetail === undefined
      ? style.portalUserDetail
      : storageSetting.portalUserDetail,
  mainColor:
    storageSetting.mainColor === undefined
      ? style.mainColor
      : storageSetting.mainColor,
}
const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
}

const actions = {
  // 修改布局设置
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
