import { createStore } from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import portalSettings from './modules/portal-settings'
import getters from './getters'
import knowledge from './modules/knowledge'
const store = createStore({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    portalSettings,
    knowledge,
  },
  getters
});


export default store
