import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

////////////////////////////////单点登录////////////////////////////////
// 获取认证中心
export function getSsoAuthUrl(clientLoginUrl) {
  return request({
    url: '/getSsoAuthUrl',
    headers: {
      isToken: false
    },
    method: 'get',
    params: {
      'clientLoginUrl':clientLoginUrl
    }
  })
}
// 根据ticket登录
export function doLoginByTicket(ticket) {
  return request({
    url: '/doLoginByTicket',
    headers: {
      isToken: false
    },
    method: 'get',
    params: {
      'ticket': ticket
    }
  })
}

// 获取SSO用户详细信息
export function getSSOInfo() {
  return request({
    url: '/sso/getInfo',
    method: 'get'
  })
}

// SSO退出方法
export function doSSOLogout() {
  return request({
    url: '/sso/logout',
    method: 'get'
  })
}

// wx登录方法
export function doWxLogin(data) {
  return request({
    url: '/wx/login?code='+ data.code,
    method: 'get'
  })
}
