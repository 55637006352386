<template>
  <div class="component-upload-image">
    <el-upload
      multiple
      drag
      :action="uploadImgUrl"
      list-type="text"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-change="handleChange"
      name="file"
      :on-remove="handleRemove"
      :show-file-list="true"
      :file-list="fileList"
      :headers="headers"
      :on-preview="handlePictureCardPreview"
      :class="{ hide: fileList.length >= limit }"
    >
       <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
         拖拽到这里或者<em>点击上传</em>
        </div>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
      </template>
      的文件
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="预览"
      width="800px"
      append-to-body
    > 
      <div v-if="isAssetTypeAnImage(dialogImageUrl)" >
          <img 
        :src="dialogImageUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
      </div>
      <div v-else>
         <video
          :src="dialogImageUrl"
          style="display: block; max-width: 100%; margin: 0 auto"
          controls="controls"
          >  </video>
     
      </div>
      

     

    </el-dialog>
  </div>
</template>



<script setup>
import { getToken } from "@/utils/auth";
import { deleteFileUrl } from "@/api/system/oss";
// import  { UploadProps, UploadUserFile } from 'element-plus'
const props = defineProps({
  modelValue: [String, Object, Array],
  // 图片数量限制
  limit: {
    type: Number,
    default: 1,
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 5,
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["png", "jpg", "jpeg","mp4","avi"],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
});

const { proxy } = getCurrentInstance();
const emit = defineEmits(['getewayData']);
const number = ref(0);
const uploadList = ref([]);
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const baseUrl = import.meta.env.VITE_APP_BASE_API;
const uploadImgUrl = ref(import.meta.env.VITE_APP_BASE_API + "/system/oss/upload"); // 上传文件服务器地址
const headers = ref({ Authorization: getToken() });
const fileList = ref([]);
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
);

watch(() => props.modelValue, val => {
   
  if (val) {
    // 首先将值转为数组
    const list = Array.isArray(val) ? val : props.modelValue.split(",");
    // 然后将数组转为对象数组
    fileList.value = list.map(item => {
      if (typeof item === "string") {
        if (item.indexOf(baseUrl) === -1) {
          item = { name:  item, url: item};
        } else {
          item = { name: item, url: item };
        }
      }
      return item;
    });
    //  console.log(fileList.value,"删除之后fileList数据")
  } else {
    fileList.value = [];
    return [];
  }
});

// 删除图片  将文件名称在文件列表里面删除只是不展示，没有在数据库进行删除
function handleRemove(file, files) {
  const findex = fileList.value.map(f => f.name).indexOf(file.name);
    // 这里只有一张图片，直接删除即可
   deleteFileUrl(file.response.data.url).then((res => {
    // console.log(res)
    }))
  fileList.value=[];
  if (findex > -1) {
    emit("update:modelValue", listToString(fileList.value));
  }
   
}
const handleChange = (uploadFile, uploadFiles) => {
  fileList.value = fileList.value.slice(0,1)
}
// 上传成功回调
function handleUploadSuccess(res) {
    if(res.code=="200"){
        uploadList.value.push({ name: res.data.fileName, url: res.data.url,ids:res.data.id });
        if (uploadList.value.length === number.value) {
            fileList.value = fileList.value.concat(uploadList.value);
            uploadList.value = [];
            number.value = 0;
            emit("update:modelValue", listToString(fileList.value));
            proxy.$modal.closeLoading();
        }
        emit("getewayData",JSON.stringify(res.data))
        // console.log(fileList.value,"fileList数据")
   } 
    else{
        handleUploadError()
        fileList.value = [];
    }

}

// 上传前loading加载
function handleBeforeUpload(file) {
  let isImg = false;
  if (props.fileType.length) {
    let fileExtension = "";
    if (file.name.lastIndexOf(".") > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
    }
    isImg = props.fileType.some(type => {
      if (file.type.indexOf(type) > -1) return true;
      if (fileExtension && fileExtension.indexOf(type) > -1) return true;
      return false;
    });
  } else {
    isImg = file.type.indexOf("image") > -1;
  }
  if (!isImg) {
    proxy.$modal.msgError(
      `文件格式不正确, 请上传${props.fileType.join("/")}格式文件!`
    );
    return false;
  }
//   if (props.fileSize) {
//     const isLt = file.size / 1024 / 1024 < props.fileSize;
//     if (!isLt) {
//       proxy.$modal.msgError(`上传头像图片大小不能超过 ${props.fileSize} MB!`);
//       return false;
//     }
//   }
  proxy.$modal.loading("正在上传，请稍候...");
  number.value++;
}

//判断文件是否符合后缀名
function isAssetTypeAnImage(ext) {
     const fileType= ext.substr( (ext.lastIndexOf(".") ) +1)
	 return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].
	 indexOf(fileType) !== -1;
}


// 文件个数超出
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`);
}

// 上传失败
function handleUploadError() {
  proxy.$modal.msgError("上传文件失败");
  proxy.$modal.closeLoading();
}

// 预览
function handlePictureCardPreview(file) {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
}

// 对象转成指定字符串分隔
function listToString(list, separator) {
  let strs = "";
  separator = separator || ",";
  for (let i in list) {
    strs += list[i].url.replace(baseUrl, "") + separator;
  }
  return strs != "" ? strs.substr(0, strs.length - 1) : "";
}
</script>

<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
:deep(.hide .el-upload--picture-card) {
  display: none;
}
// 去掉动画效果
:deep(.el-list-enter-active),
:deep(.el-list-leave-active) {
  transition: all 0s;
}
:deep(.el-list-enter, .el-list-leave-active) {
  opacity: 0;
  transform: translateY(0);
}
</style>
